export const palette = {
  transparent: 'transparent',
  dark: '#333',
  light: ' #fff'
}

const styles = {
  palette
}

export default styles
